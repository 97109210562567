<template lang="pug">
div
  el-card(style="width: 850px;position: absolute;top: 40%;left: 0;right: 0;margin: auto;")
    el-alert(
      type="icon"
      description="已核销"
      show-icon)

</template>

<script>
export default {
  data: () => ({
    team_id: '',
    username: '',
    teamname: '',
    teams: [],
    text: '',
    icon: 'success',
    type: 'login',
  }),
  mounted () {},
  computed: {
    team () {
      return this.$s.getters.team
    },
    profile () {
      return this.$s.getters.profile
    },
  },
  created () {},
  methods: {
    jiaru () {
      this.$api.getUserTeams().then((res) => {
        if (res.status === 200) {
          // this.teams = res.data
          res.data.forEach((item) => {
            if (item.id === this.team_id) {
              this.icon = 'error'
              this.type = 'error'
              this.text = '你已经存在这个组织'
            }
          })
          if (this.type === 'ok') {
            this.$api
              .addTeamUser2({
                team_id: this.team_id,
                user_name: this.profile.user_name,
              })
              .then((res) => {
                if (res.status === 200) {
                  this.icon = 'success'
                  this.type = 'error'
                  this.text = '用户加入成功'
                } else {
                  this.icon = 'error'
                  this.type = 'error'
                  this.text = '你已经存在这个组织'
                }
              })
          }
        }
      })
    },
    toLogin () {
      this.$router.replace({
        name: 'login',
        query: { redirect: this.$router.currentRoute.fullPath },
      })
    },
  },
}
</script>
